/* eslint-disable global-require */

import React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";
import "../styles/components/ebook/ebook-normalize.scss";
import "../styles/components/ebook/ebook-base.scss";
import "../styles/components/ebook/ebook.scss";
import References2020 from "../modules/References2020/References2020";

class PageEbook extends React.Component {
  constructor() {
    super();
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.addIframeListener();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.iframeAction, false);
  }

  iframeLoad = () => {
    this.addIframeListener();
  };

  addIframeListener = () => {
    window.addEventListener("message", this.iframeAction, false);
  };

  iframeAction = e => {
    if (e.data.type === "resize") {
      this.iframe.current.style.minHeight = `${e.data.size}px`;
    }
  };

  render() {
    const currentPage = this.props.data.wordpressPage;
    const metaTitle = currentPage.yoast.title || "";
    const metaDesc = currentPage.yoast.metadesc || "";
    const metaKeyWords = currentPage.yoast.metakeywords || "";

    return (
      <Layout>
        <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
        <section className="ebook__content">
          <div className="plama-duza">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60db362ad2b0601c886cb93a_Plama_Y.svg")}
              loading="lazy"
              alt=""
              className="image-43"
            />
          </div>
          <div className="plama-dolna">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60db362b3584d65f65696326_Plama_Z.svg")}
              loading="lazy"
              alt=""
              className="image-42"
            />
          </div>
          <div className="plama-prawa">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60db362abf5cce7212ab3d4c_Plama_01.svg")}
              loading="lazy"
              alt=""
              className="image-40"
            />
          </div>
          <div className="plama-lewa">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60db362a14635b6ab396e0a7_Plama_X.svg")}
              loading="lazy"
              alt=""
              className="image-39"
            />
          </div>
          <div className="plama-luzem">
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60db362a78574d867a195407_Plama_03.svg")}
              loading="lazy"
              alt=""
              className="image-38"
            />
          </div>
          <div id="BTTT" className="w-layout-grid hero">
            <div
              id="w-node-_558b321e-a65c-959f-ce42-9139715cf71f-0303cecd"
              className="div-block-10"
            >
              <div className="tytul">Magento eBook</div>
              <div className="podtytul">
                Check how to optimize <br />
                eCommerce and rise CTR
              </div>
              <div className="info">No Spam only Valuable Content max twice a month</div>
              <div className="w-form">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                  ref={this.iframe}
                  onLoad={this.iframeLoad}
                  id="fm-fc-f-kuqk7dhy53"
                  src="https://forms.freshmail.io/f/w8wwvjlicv/kuqk7dhy53/index.html"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  width="100%"
                />
              </div>
            </div>
            <img
              /* eslint-disable-next-line import/no-unresolved,global-require */
              src={require("../images/ebook/60d50d5f8eb803f46fba6767_3debook_NEWEST.png")}
              loading="lazy"
              id="w-node-_137b0c60-9ae7-d6c2-176b-4283c129dc18-0303cecd"
              alt=""
              className="herophoto"
            />
          </div>
          <div className="quota">
            <div className="w-container">
              <div className="text-block-7">
                Improving your load time by <span className="text-span bold">0.1 s</span>
                <br />
                can increase average order value of <span className="text-span-2 bold">9.2%</span>
              </div>
              <div className="text-block-8">
                Google/Deloitte
                <br />
                Milliseconds Make Millions
              </div>
            </div>
          </div>
          <div className="pandabasictips">
            <div className="container-9 w-container">
              <div className="text-block-3">
                Panda’s Basic tips and Revolution Solution based on Hyvä
                <br />
                Improving your Magento eCommerce
              </div>
              <div className="w-row">
                <div className="column w-col w-col-4">
                  <div className="div-block">
                    <div className="headline">Loading</div>
                    <img
                      /* eslint-disable-next-line import/no-unresolved,global-require */
                      src={require("../images/ebook/60d377b737f9f6ac8a168983_LCP_pion_mod-2f1f05d066caa20ce2e649dfecc3db9b-removebg-preview.png")}
                      loading="lazy"
                      alt=""
                      className="image-2"
                    />
                  </div>
                </div>
                <div className="w-col w-col-4">
                  <div>
                    <div className="headline">Interactivity</div>
                    <img
                      /* eslint-disable-next-line import/no-unresolved,global-require */
                      src={require("../images/ebook/60d376894aa9cd6cad70faca_FID_pion_mod-1ab2c0a0b2cdc8b2ef63b7016351f808-removebg-preview.png")}
                      loading="lazy"
                      alt=""
                      className="image"
                    />
                  </div>
                </div>
                <div className="w-col w-col-4">
                  <div>
                    <div className="headline">Visual Stability</div>
                    <img
                      /* eslint-disable-next-line import/no-unresolved,global-require */
                      src={require("../images/ebook/60d37406c0196f6a9ae6619c_CLS_pion_mod-fad4082991dfde4fead4564ca8dfbe63-removebg-preview.png")}
                      loading="lazy"
                      alt=""
                      className="image-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="EbookContent" className="ebookcontent">
            <div className="w-container">
              <img
                /* eslint-disable-next-line import/no-unresolved,global-require */
                src={require("../images/ebook/60d39547784c06fe1c969de8_Group_2.png")}
                loading="lazy"
                width="512.5"
                sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px"
                alt=""
                className="image-9"
              />
              <div className="div-block-4">
                <div className="div-block-5">
                  <div className="srodtytulbold">The Ebook contains</div>
                  <div className="columns-6 w-row">
                    <div className="column-13 w-col w-col-1 w-col-small-1 w-col-tiny-1">
                      <img
                        /* eslint-disable-next-line import/no-unresolved,global-require */
                        src={require("../images/ebook/60d38fcbc0196f0d40e71762_kropa.png")}
                        loading="lazy"
                        alt=""
                        className="image-35"
                      />
                    </div>
                    <div className="column-15 w-col w-col-11 w-col-small-11 w-col-tiny-11">
                      <div className="list-item">toolbox to measure Core Web Vitals</div>
                    </div>
                  </div>
                  <div className="columns-6 w-row">
                    <div className="column-13 w-col w-col-1 w-col-small-1 w-col-tiny-1">
                      <img
                        /* eslint-disable-next-line import/no-unresolved,global-require */
                        src={require("../images/ebook/60d38fcbc0196f0d40e71762_kropa.png")}
                        loading="lazy"
                        alt=""
                        className="image-35"
                      />
                    </div>
                    <div className="column-14 w-col w-col-11 w-col-small-11 w-col-tiny-11">
                      <div className="list-item">
                        to-do checklist improving speed, interactivity, and visual stability
                      </div>
                    </div>
                  </div>
                  <div className="columns-6 w-row">
                    <div className="column-13 w-col w-col-1 w-col-small-1 w-col-tiny-1">
                      <img
                        /* eslint-disable-next-line import/no-unresolved,global-require */
                        src={require("../images/ebook/60d38fcbc0196f0d40e71762_kropa.png")}
                        loading="lazy"
                        alt=""
                        className="image-35"
                      />
                    </div>
                    <div className="column-16 w-col w-col-11 w-col-small-11 w-col-tiny-11">
                      <div className="list-item">
                        Hyvä’s support for permanent improvement of Core Web Vitals metrics
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container-10 w-container">
              <div className="div-block-8">
                <img
                  /* eslint-disable-next-line import/no-unresolved,global-require */
                  src={require("../images/ebook/60d38a1effe8f52b2cb2c4ab_SzNiedziela.png")}
                  loading="lazy"
                  alt=""
                  className="image-36"
                />
              </div>
              <div className="div-block-9">
                <div className="szymontext">
                  Szymon Niedziela
                  <br />
                  <span className="text-span-3">
                    CEO &amp; Co-Founder
                    <br />
                    sniedziela@pandagroup.co
                  </span>
                </div>
                <img
                  /* eslint-disable-next-line import/no-unresolved,global-require */
                  src={require("../images/ebook/60db362adbc0fa07af89a83d_Listki_trzy.svg")}
                  loading="lazy"
                  width="111"
                  alt=""
                  className="image-41"
                />
              </div>
            </div>
          </div>
          <div className="ebookcontent3">
            <div className="container-4 w-container">
              <div className="columns w-row">
                <div className="column-2 w-col w-col-6">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d49473c4b15c1fa32ac878_Certyfikaty.png")}
                    loading="lazy"
                    alt=""
                    className="image-11"
                  />
                </div>
                <div className="column-3 w-col w-col-6">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d49473248cd23e60fcc64e_Certyfikaty2.png")}
                    loading="lazy"
                    alt=""
                    className="image-12"
                  />
                </div>
              </div>
              <div className="columns-2 w-row">
                <div className="column-4 w-col w-col-6">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d494730f1878943afdd769_Certyfikaty3.png")}
                    loading="lazy"
                    alt=""
                    className="image-13"
                  />
                </div>
                <div className="column-5 w-col w-col-6">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d494735fdf0116d7114e79_Certyfikaty4.png")}
                    loading="lazy"
                    alt=""
                    className="image-14"
                  />
                </div>
              </div>
              <div className="w-row">
                <div className="column-6 w-col w-col-4">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d3740696587e46f35808ee_adobe_solution_partner_blonze.png")}
                    loading="lazy"
                    width="251"
                    alt=""
                    className="image-15"
                  />
                </div>
                <div className="column-8 w-col w-col-5">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d3740890314af8b006d7a7_logo-compact.png")}
                    loading="lazy"
                    width="280"
                    alt=""
                    className="image-17"
                  />
                </div>
                <div className="column-7 w-col w-col-3">
                  <img
                    /* eslint-disable-next-line import/no-unresolved,global-require */
                    src={require("../images/ebook/60d3740637f9f650ff1674dc_clutch.png")}
                    loading="lazy"
                    alt=""
                    className="image-16"
                  />
                </div>
              </div>
            </div>
          </div>
          <section id="About-Panda" className="about-panda">
            <div className="container-5 w-container">
              <div className="srodtytulbold">About Panda Group</div>
              <div className="srodtytultext">
                Magento Development Agency since 2013, <br />
                operating in 14 Countries all over the World
              </div>
              <img
                /* eslint-disable-next-line import/no-unresolved,global-require */
                src={require("../images/ebook/60d4a00ee34ae01f203a7c6e_World_map.png")}
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 718px, 930px"
                alt=""
                className="image-18"
              />
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-medium-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-15">25</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-medium-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-16">Magento Experts</div>
                </div>
              </div>
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-19">100+</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-17">Magento Projects in Portfolio</div>
                </div>
              </div>
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-15">78</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-16">satisfied Clients</div>
                </div>
              </div>
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-19 interlinebigger">100%</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-16 _3lines">
                    revenue growth during <br />
                    5 years of cooperation <br />
                    with one of our Clients
                  </div>
                </div>
              </div>
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-15">66×</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-16 _3lines centertext">
                    revenue growth <br />
                    in 2013/20 for another
                  </div>
                </div>
              </div>
              <div className="columns-3 w-row">
                <div className="column-9 w-col w-col-4 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-19">5</div>
                </div>
                <div className="column-10 w-col w-col-8 w-col-small-6 w-col-tiny-6">
                  <div className="text-block-16 lowinter">years average cooperation time</div>
                </div>
              </div>
            </div>
          </section>
          <div className="businessclients">
            <div className="container-6 w-container">
              <div className="srodtytulbold">Business success of our Clients is our success</div>
            </div>
          </div>
        </section>
        <div className="home ebook__references">
          <References2020 data={this.props.data.allWordpressWpPortfolio} />
        </div>
        <div className="backtotop">
          <div className="container-8 w-container">
            <a id="BTT" href="#BTTT" className="link-block w-inline-block">
              <img
                /* eslint-disable-next-line import/no-unresolved,global-require */
                src={require("../images/ebook/60d4a9385e444c3f87c7257c_BackToTop.png")}
                loading="lazy"
                alt=""
                className="image-20"
              />
            </a>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageEbook;

export const pageQuery = graphql`
  query eBookPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      yoast {
        title
        metakeywords
        metadesc
      }
    }
    allWordpressWpPortfolio {
      nodes {
        id
        title
        link
        path
        acf {
          client_reference
          client_position
          client_name
          country_flag
          short_title
          project_logo {
            title
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          client_photo {
            id
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          mock_up_main {
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          page_logo {
            id
            source_url
            alt_text
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          services {
            icon
            name
          }
          short_text
          shop_link
          country_flag
          what_wewe_done
        }
        wordpress_id
      }
    }
  }
`;
