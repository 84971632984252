import React from "react";

const ProgressBar = ({ slideIndex }) => {
  return (
    <div className="progressbar">
      <span className={`progressbar__percent progressbar__percent-${slideIndex}`} />
    </div>
  );
};

export default ProgressBar;
