import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide2020 from "./components/Slide2020";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

class References2020 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      slideIndex: 0,
      // eslint-disable-next-line react/no-unused-state
      updateCount: 0
    };
  }

  render() {
    const allData = this.props.data.nodes;
    const data = allData.filter(portfolio => portfolio.wordpress_id !== 129);

    const sliderSettings = {
      arrows: true,
      speed: 500,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 400000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false
          }
        }
      ],
      beforeChange: (oldIndex, newIndex) => {
        setTimeout(() => {
          this.setState({ slideIndex: newIndex });
        }, 10);
      }
    };

    return (
      <section className="references">
        <div className="section__header references__header">
          <div className="section__title--small">see our happy client</div>
          <h2 className="section__title--large">references</h2>
        </div>
        <div className="container">
          <div className="references--top">
            {/* eslint-disable */}
            <Slider {...sliderSettings}>
              {data.map(slide => {
                return <Slide2020 data={slide} key={data[1].id} />;
              })}
            </Slider>
            <ProgressBar slideIndex={this.state.slideIndex} />
            {/* eslint-enable */}
          </div>
        </div>
      </section>
    );
  }
}

export default References2020;
