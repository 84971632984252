import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const Slide2020 = ({ data }) => {
  const { id, acf } = data;
  const referenceVideo = acf.client_reference_video // eslint-disable-line no-nested-ternary
    ? acf.client_reference_video
    : null;

  return (
    <div key={id} className="reference-slide">
      <div className="reference-slide__wrapper">
        <div className="top-corner" />
        <div className="bottom-corner" />
        <div className="client__logo__wrapper " />
        <div className="reference__content">
          <div className="client__wrapper">
            {acf.client_photo != null ? (
              <img
                className="client__image lazyload"
                data-src={acf.client_photo.localFile.childImageSharp.original.src}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                alt={acf.client_name}
              />
            ) : null}
          </div>
          <div className="client__logo">
            {acf.project_logo != null ? (
              <img
                className="lazyload"
                data-src={acf.project_logo.localFile.childImageSharp.original.src}
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                alt="pandagroup.co"
              />
            ) : null}
          </div>
          {referenceVideo ? (
            /* eslint-disable-next-line jsx-a11y/media-has-caption */
            <video controls className="reference__video">
              <source
                src={`${referenceVideo.url.source_url}#t=0.5`}
                type={referenceVideo.url.mime_type}
              />
              Your browser does not support the video tag.
            </video>
          ) : null}
          <div
            className="reference__content--top"
            dangerouslySetInnerHTML={renderHTML(acf.client_reference)}
          />
          <div className="reference__content--bottom">
            <div className="reference__person">
              <div className="client__name">{acf.client_name}</div>
              <div className="client__position">{acf.client_position}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide2020;
